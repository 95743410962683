<template>
    <button
        class="set-status-button"
        :class="{active, disabled}"
        @click="toggleState()"
    >
        <icon
            :name="icon"
        />
    </button>
</template>

<script>
import {
    NotificationSettingState,
    NotificationType,
} from "@backend/notification-settings/types";

export default {
    name: "NotificationSettingButton",
    inheritAttrs: false,
    props: {
        setting: {
            type: Object,
            required: true,

        },
        disabled: { type: Boolean },
    },
    computed: {
        active() {
            return this.setting.state !== NotificationSettingState.disabled;
        },
        color() {
            if (this.active) {
                return "var(--primary-text)";
            }
            return "var(--primary)";
        },
        icon() {
            if (this.setting.type === NotificationType.email) {
                if (this.active) {
                    return "mail";
                }
                return "mail-off";
            } else if (this.setting.type === NotificationType.ping) {
                if (this.active) {
                    return "bell";
                }
                return "bell-off";
            } else if (this.setting.type === NotificationType.push) {
                if (this.active) {
                    return "push";
                }
                return "push-off";
            }
            if (this.active) {
                return "bell";
            }
            return "bell-off";
        },
    },
    methods: {
        toggleState() {
            if (this.disabled) {
                return;
            }
            if (this.setting.state !== NotificationSettingState.active) {
                this.setting.state = NotificationSettingState.active;
            } else {
                this.setting.state = NotificationSettingState.disabled;
            }
            this.$emit("input", this.setting);
        },
    },
};
</script>

<style lang="scss" scoped>
.set-status-button {
    --icon-color: var(--primary);
    display: inline-flex;
    width: var(--icon-size);
    height: var(--icon-size);
    border: 2px solid var(--primary);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    background-color: transparent;

    &.active {
        background-color: var(--primary);
        --icon-color: var(--primary-text);

    }

    &.disabled {
        --icon-color: #{$light-grey};
        border: 2px solid $light-grey;
        opacity: 1;

        &.active{
            background-color: $light-grey;
            --icon-color: #{$mid-grey};
        }
    }
}
</style>
