<template>
    <div class="notification-settings">
        <div class="notification-settings-title">
            <h2>
                {{ $t("notification_settings_headline") }}
            </h2>
            <span>
                {{ $t("notification_settings_description") }}
            </span>
        </div>
        <system-message
            v-if="hasError"
            type="fetchData"
        />
        <loading-wrapper
            v-else
            :is-loading="loading"
            class="notification-grid"
        >
            <div class="notification-activity-row head">
                <div class="activity-label"></div>
                <div class="notification-actions">
                    <div
                        v-for="type in notificationSettings.types"
                        :key="type"
                        class="meta"
                    >
                        {{ $t(notificationTypeTitleI18nMap[type]) }}
                    </div>
                </div>
            </div>
            <div
                v-for="activity in notificationSettings.activities"
                :key="activity"
                class="notification-activity-row bottom-line"
            >
                <div class="activity-label">
                    <div
                        class="title"
                        :class="{
                            'strikethrough': activityDisabled(activity)
                        }"
                    >
                        {{
                            activity === "USER_MENTIONED"
                                ? `@${currentUserProfile.displayName}`
                                : $t(activityTitleI18nMap[activity].title)
                        }}
                    </div>
                    <div class="description meta">
                        {{ $t(activityTitleI18nMap[activity].description) }}
                    </div>
                </div>
                <div class="notification-actions">
                    <notification-setting-button
                        v-for="type in notificationSettings.types"
                        :key="type + activity"
                        :setting="getSetting({activity, type})"
                        @input="toggleStatus($event)"
                    />
                </div>
            </div>
        </loading-wrapper>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import { notificationSettingsService } from "@web/services";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import { getGlobalConfiguration } from "@web/global-config";
import { analytics } from "@web/analytics";
import { ActivityType } from "@backend/common/notification/activity-types";
import {
    NotificationSettingState,
    NotificationType,
} from "@backend/notification-settings/types";
import NotificationSettingButton from "@web/views/intranet/UserSettings/notification-settings/NotificationSettingButton";
import { PROFILE_MODULE_NAME } from "@/store/profile/profile";
import { CURRENT_USER_PROFILE } from "@/store/profile/getters";

const activityTitleI18nMap = {
    [ActivityType.commentPublished]: {
        title: "activity_title_comment_published",
        description: "activity_description_comment_published",

    },
    [ActivityType.newsPublished]: {
        title: "activity_title_news_published",
        description: "activity_description_news_published",

    },
    [ActivityType.postPublished]: {
        title: "activity_title_post_published",
        description: "activity_description_post_published",

    },
    [ActivityType.reactionCreated]: {
        title: "activity_title_reaction_created",
        description: "activity_description_reaction_created",

    },
    [ActivityType.userMentioned]: {
        title: "activity_title_user_mentioned",
        description: "activity_description_user_mentioned",

    },
};

const notificationTypeTitleI18nMap = {
    [NotificationType.email]: "email",
    [NotificationType.ping]: "ping",
    [NotificationType.push]: "push",
};

const analyticsKeyMap = {
    [NotificationType.email]: "analytics_event_name_notification_settings_update_email",
    [NotificationType.ping]: "analytics_event_name_notification_settings_update_ping",
    [NotificationType.push]: "analytics_event_name_notification_settings_update_push",
};

export default {
    name: "NotificationSettings",
    components: {
        NotificationSettingButton,
    },
    data() {
        return {
            loading: true,
            notificationSettings: {},
            activityTitleI18nMap,
            notificationTypeTitleI18nMap,
            analyticsKeyMap,
            hasError: undefined,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, [INTRANET]),
        ...mapState(AUTH_MODULE_NAME, [CURRENT_USER]),
        ...mapState(PROFILE_MODULE_NAME, [CURRENT_USER_PROFILE]),
    },
    mounted() {
        this.fetchNotificationSettings();
    },
    methods: {
        getSetting({ activity, type }) {
            return this.notificationSettings.settings.find(setting => setting.activity === activity && setting.type === type) || {
                activity,
                type,
                state: NotificationSettingState.active,
            };
        },
        activityDisabled(activity) {
            return !this.notificationSettings.types.some((type) => this.getSetting({ activity, type }).state === NotificationSettingState.active);
        },
        async fetchNotificationSettings() {
            this.loading = true;
            try {
                const { data } = await notificationSettingsService.fetchSettings({
                    intranetUid: this.intranet.uid,
                    userUid: this.currentUser.uid,
                });
                this.notificationSettings = data;
            } catch (error) {
                this.hasError = error;
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("notification_settings_fetch_failed")}`,
                });
            } finally {
                this.loading = false;
            }
        },
        async toggleStatus({ state, activity, type }) {
            try {
                await notificationSettingsService.updateSetting({
                    intranetUid: this.intranet.uid,
                    activity,
                    type,
                    state,
                    userUid: this.currentUser.uid,
                });
                this.sendAnalyticsEvent({ state, activity, type });
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("notification_settings_update_failed")}`,
                });
            }
        },
        sendAnalyticsEvent({ state, activity, type }) {
            const analyticsEventName = getGlobalConfiguration()[this.analyticsKeyMap[type]];
            analytics.log(analyticsEventName, {
                activityType: activity,
                notificationType: type,
                status: state,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.notification-grid {
    min-height: 10rem;
    max-width: 50rem;
}

.notification-activity-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 360px) {
        display: block;
    }

    &.bottom-line {
        border-bottom: 1px solid var(--low-contrast);
    }

    &.head {
        text-align: center;

        .notification-actions {
            justify-content: space-evenly;
            margin-bottom: 0;

            > * {
                width: 48px;
            }
        }
    }

    @media(max-width: 360px) {
        &.head {
            display: none;
        }
    }

    > * {
        margin: 1rem 0;
    }
}

.title {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
}

.strikethrough {
    text-decoration: line-through;
}

.notification-actions {
    display: flex;
    white-space: nowrap;
    margin-left: 1rem;

    > * ~ * {
        margin-left: 2rem;
    }

    @media(max-width: 360px) {
        justify-content: space-evenly;
        margin-left: 0;

        * {
            margin-left: 0;
        }
    }
}
</style>
