import { ActivityType } from "@backend/common/notification/activity-types";

/**
 * Several fields will be added to this enum later.
 * Even though this implementation does not make much sense in it's current state this choice ensures that adding more types will be easy.
 */
export enum NotificationSettingState {
    active = "active",
    disabled = "disabled",
    // ACTIVE_FOR_CATEGORY = "category",
}

/**
 * There probably wont be many additions to this enum in the foreseeable future.
 */
export enum NotificationType {
    ping = "ping",
    email = "email",
    push = "push",
}

/**
 * filter is some conditional that will be implemented with conditional active states such as ACTIVE_FOR_CATEGORY
 */
export interface NotificationUserSetting {
    activity: ActivityType;
    type: NotificationType;
    state: NotificationSettingState;
    // filter: unknown; TODO HALO-2492 use when Filters get implemented
}

export interface NotificationUserSettings {
    settings: NotificationUserSetting[];
    activities: ActivityType[];
    types: NotificationType[];
}

export interface NotificationSettingsInFirestore {
    settings: NotificationUserSetting[];
}
